<template>
    <div>
      <div class="modal fade" data-backdrop="static" data-keyboard="false" id="deficiencyNew" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">ADD DEFICIENCY</h5>
<!--              <button type="button" @click.prevent="resetDeficiency" class="close" ref="closeAddDeficiency" data-dismiss="modal" aria-label="Close">-->
<!--                <span aria-hidden="true">&times;</span>-->
<!--              </button>-->
            </div>
            <div class="modal-body">
              <div class="form-group row no-gutters mb-1">
                <label for="ReportTitle" class="font-weight-bold col-sm-3 col-form-label">PSC Deficiency Code</label>
                <div class="col-sm-9">
                  <button class="btn border btn-block text-left btn-sm"
                          data-target="#pscCodeNew"
                          data-toggle="modal"
                          style="padding-top: .35rem; padding-bottom: .35rem;"
                  >
                    <span style="font-size: 14px;">
                      {{this.code.hasOwnProperty('id') ? '(' + this.code.code.toUpperCase() + ') ' +  this.code.description.toUpperCase() : '--- SELECT DEFICIENCY CODE ---' }}
                    </span>
                    <span style="float: right">
                      <font-awesome-icon icon="chevron-down"/>
                    </span>
                  </button>
                </div>
              </div>
              <div class="form-group row no-gutters mb-1">
                <label for="ReportTitle" class="font-weight-bold col-sm-3 col-form-label">PSC Action Code</label>
                <div class="col-sm-9">
                  <select name="" id="" class="form-control form-control-sm" v-model="fields.deficiency.psc_action_code_id">
                    <option :value="null"> --- SELECT ACTION CODE ---</option>
                    <option :value="actionCode.id" v-for="actionCode in pscActionCodeKeys ">
                      ({{actionCode.code.toUpperCase()}}) {{actionCode.description.toUpperCase()}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row no-gutters mb-1">
                <label for="ReportTitle" class="font-weight-bold col-sm-3 col-form-label">Deficiency Description</label>
                <div class="col-sm-9">
                  <textarea class="form-control form-control-sm" id="ReportTitle" rows="3"
                            v-model="fields.deficiency.description"
                            required>
                  </textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" @click.prevent="resetDeficiency" class="btn btn-sm btn-secondary" ref="closeAddDeficiency" data-dismiss="modal" aria-label="Close">Close</button>
              <button type="button" class="btn btn-primary btn-sm" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''"  @click="confirmAddDeficiency" :disabled="hasChanges === false">Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="pscCodeNew" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">SELECT DEFICIENCY CODE</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body overflow-auto " style="height: 50vh">
              <template v-for="(node,index) in pscCodeKeys">
                <h6 class="h6">({{node.code}}) {{ node.description }}</h6>
                <psc-action-code-component :psc-code="code" :dept="10 + index" :node="node" @setPscCode="setSelectedPscCode">

                </psc-action-code-component>
              </template>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
 import PscActionCodeComponent from "./PscActionCodeComponent.vue";
 import {mapActions, mapGetters} from "vuex";
 import {AlertService} from "../../../../services/AlertService";
 import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
 import {DataService} from "@/services/DataService";

 export default {
   name:'PscDeficiencyNew',
   components: {PscActionCodeComponent},
   data(){
     return {
       code:{},
       fields:{
         id:null,
         deficiency:{
           description:'',
           psc_code_id:null,
           psc_action_code_id:null
         }
       }
     }
   },
   methods:{
     faChevronDown() {
       return faChevronDown
     },
     ...mapActions(['updatePscInfo']),
     setSelectedPscCode(value){
       this.code=value;
       this.fields.deficiency.psc_code_id=value.id
     },

     resetDeficiency(){
       this.fields={
         id:null,
         deficiency:{
           description:'',
           psc_code_id:null,
           psc_action_code_id:null
         }
       }
       this.code={}
     },
     async confirmAddDeficiency(){
       if(await AlertService.confirmCreateAlert('Do you want to Add New Deficiency?')){
          this.fields.id=this.$route.params.id;
          this.fields._source = 'KIDS - PSC ADD NEW DEFICIENCY'
          const response = await this.updatePscInfo(this.fields)
          if(response){
            AlertService.successAlert('A New Deficiency has been Added Successfully!','Add Deficiency')
            this.fields={
              id:null,
              deficiency:{
                description:'',
                psc_code_id:null,
                psc_action_code_id:null
              }
            }
            this.code={}
            this.$refs.closeAddDeficiency.click();
          }
       }
     }
   },
   computed:{
     ...mapGetters(['pscCodeKeys','pscActionCodeKeys']),
     hasChanges() {
       let originalData = {
         psc_code_id : null,
         psc_action_code_id : null,
         description : ''
       }

       let editedData = {
         psc_code_id        : this.fields.deficiency.psc_code_id,
         psc_action_code_id : this.fields.deficiency.psc_action_code_id,
         description        : this.fields.deficiency.description,
       };

       return DataService.checkIfChanged(originalData, editedData);
     }
   }
 }
</script>
<style scoped>

</style>
